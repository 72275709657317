@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#stats {
  position: relative;
  width: 100%;
  height: 80px;
}
#main {
  position: relative;
  margin: 0;
}
#canvas-wrapper,
#scatter-gl-container {
  position: relative;
}
/* 
#header1 {
  background-image: url(../../static/images/common/title-bg.png);
} */

#stats canvas {
  opacity: 0.25;
}

@font-face {
  font-family: PingFang SC;
  src: url("/ttf/PingFang.ttf");
}

@font-face {
  font-family: Inter;
  src: url("/ttf/Inter.ttf");
}

@font-face {
  font-family: Atomic Age;
  src: url("/ttf/AtomicAge.ttf");
}