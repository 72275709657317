*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.top-12 {
  top: 3rem;
}

.right-5 {
  right: 1.25rem;
}

.left-5 {
  left: 1.25rem;
}

.top-10 {
  top: 2.5rem;
}

.left-10 {
  left: 2.5rem;
}

.right-10 {
  right: 2.5rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.right-0 {
  right: 0;
}

.m-\[10px\] {
  margin: 10px;
}

.mx-\[32px\] {
  margin-left: 32px;
  margin-right: 32px;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-\[2px\] {
  margin-left: 2px;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-\[480px\] {
  height: 480px;
}

.h-6 {
  height: 1.5rem;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[280px\] {
  height: 280px;
}

.h-\[40px\] {
  height: 40px;
}

.w-full {
  width: 100%;
}

.w-\[640px\] {
  width: 640px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[280px\], .w-\[280\] {
  width: 280px;
}

.w-6 {
  width: 1.5rem;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[16px\] {
  width: 16px;
}

.flex-1 {
  flex: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-\[12px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12px * var(--tw-space-x-reverse));
  margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-\[4px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4px * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(4px * var(--tw-space-y-reverse));
}

.space-x-\[6px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6px * var(--tw-space-x-reverse));
  margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)) );
}

.divide-x-\[3px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(3px * var(--tw-divide-x-reverse));
  border-left-width: calc(3px * calc(1 - var(--tw-divide-x-reverse)) );
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-\[\#6F77FE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(111 119 254 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#D4FF2F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 255 47 / var(--tw-bg-opacity));
}

.bg-\[\#02FFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#C7C7C7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(199 199 199 / var(--tw-bg-opacity));
}

.bg-\[\#ffffff\]\/20 {
  background-color: #fff3;
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-\[url\(\'\/images\/space_break\/home\/background\.png\'\)\] {
  background-image: url("/images/space_break/home/background.png");
}

.from-\[\#3c2673\] {
  --tw-gradient-from: #3c2673;
  --tw-gradient-to: #3c267300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#0B72FF\] {
  --tw-gradient-from: #0b72ff;
  --tw-gradient-to: #0b72ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-\[\#6476FE\] {
  --tw-gradient-to: #6476fe00;
  --tw-gradient-stops: var(--tw-gradient-from), #6476fe, var(--tw-gradient-to);
}

.to-\[\#6a2383\] {
  --tw-gradient-to: #6a2383;
}

.to-\[\#6F77FE\] {
  --tw-gradient-to: #6f77fe;
}

.bg-\[length\:200\%\] {
  background-size: 200%;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.p-\[10px\] {
  padding: 10px;
}

.p-3 {
  padding: .75rem;
}

.p-\[2px\] {
  padding: 2px;
}

.pl-\[16px\] {
  padding-left: 16px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-\[\'Arial\'\] {
  font-family: Arial;
}

.font-\[\'Inter\'\] {
  font-family: Inter;
}

.font-\[\'Atomic_Age\'\] {
  font-family: Atomic Age;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-\[100px\] {
  font-size: 100px;
}

.text-\[90px\] {
  font-size: 90px;
}

.text-\[64px\] {
  font-size: 64px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[48px\] {
  font-size: 48px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[72px\] {
  font-size: 72px;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[16px\] {
  font-size: 16px;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.leading-\[80px\] {
  line-height: 80px;
}

.leading-\[64px\] {
  line-height: 64px;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-\[32px\] {
  line-height: 32px;
}

.leading-3 {
  line-height: .75rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-4 {
  line-height: 1rem;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-getWin {
  --tw-shadow: inset 0 0 300px 100px #28622c94;
  --tw-shadow-colored: inset 0 0 300px 100px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-getLose {
  --tw-shadow: inset 0 0 300px 100px #b92a2a80;
  --tw-shadow-colored: inset 0 0 300px 100px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#stats {
  width: 100%;
  height: 80px;
  position: relative;
}

#main {
  margin: 0;
  position: relative;
}

#canvas-wrapper, #scatter-gl-container {
  position: relative;
}

#stats canvas {
  opacity: .25;
}

@font-face {
  font-family: PingFang SC;
  src: url("/ttf/PingFang.ttf");
}

@font-face {
  font-family: Inter;
  src: url("/ttf/Inter.ttf");
}

@font-face {
  font-family: Atomic Age;
  src: url("/ttf/AtomicAge.ttf");
}

/*# sourceMappingURL=index.273649c3.css.map */
